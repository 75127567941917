import {request} from '@/utils/request'

// 公告
export function getShopNotice(data) {
  return request('GET','/notice/page', data, true)
}

// 公告
export function getShopNoticeDetail(id) {
  return request('GET','/notice/' + id, {}, true)
}
