<template>
    <div class="shop_main">
        <div class="shop_search_menu" style="height: 60px">
            <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
            <i class="el-icon-arrow-right"></i>
            <a href="javascript:void(0)" @click="$router.replace('/shop/notice')">商城公告</a>
            <i class="el-icon-arrow-right"></i>
            <span class="on">公告详情</span>
        </div>

        <div class="shop_help ql-snow" v-loading="isRequestLoading" style="padding: 52px 54px">
            <div class="head" style="border-bottom: #DEDEDE solid 1px; padding-bottom: 16px">
                <h3 class="title">商城公告</h3>

                <div class="contact">
                    <span class="text">如有疑问？请联系</span>
                    <span class="text phone">客服热线：<span class="number">{{consumerPhone}}</span></span>
                </div>
            </div>

            <div style="padding: 40px 42px; display: flex; flex-direction: row; align-items: center; justify-content: space-between">
                <span style="font-size: 14px; color: #303133; line-height: 22px; font-weight: bold">{{title}}</span>
                <span style="font-size: 12px; color: #C0C4CC; line-height: 22px;">{{createAt.split('T')[0]}}</span>
            </div>

            <div class="content notice ql-editor maxImageWidth" style="background: #F8F8F8; margin-top: 0" v-html="content">

            </div>
        </div>
    </div>
</template>

<script>
  import {getShopNoticeDetail} from '../../../api/shop/notice'
  import {consumerPhone} from "../../../utils/config";
  export default {
    name: "shopNoticeDetail",
    data () {
      return {
        title: '',
        content: '',
        createAt: '',
        isRequestLoading: false,
        consumerPhone: consumerPhone
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getInfo()
    },
    methods: {
      getInfo () {
        this.isRequestLoading = true;
        getShopNoticeDetail(this.$route.query.id).then(res => {
          this.isRequestLoading = false;
          if (res.code === 0) {
            this.title = res.data.title;
            this.content = res.data.content;
            this.createAt = res.data.createAt
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      }
    },
    components: {}
  }
</script>
